import React, { useMemo } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { rem } from 'polished'

import cn from 'clsx'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import Tooltip from 'components/Luxkit/Tooltip'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { checkCanViewLuxPlusBenefits, isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'
import AirportTransferUpsellTile from './UpsellTiles/AirportTransferUpsellTile'
import CarHireUpsellTile from './UpsellTiles/CarHireUpsellTile'
import FlightUpsellTile from './UpsellTiles/FlightUpsellTile'
import InsuranceUpsellTile from './UpsellTiles/InsuranceUpsellTile'
import TourOptionUpsellTile from './UpsellTiles/TourOptionUpsellTile'
import useAllUpsells from 'hooks/Upsells/useAllUpsells'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { getOrderDateRange } from './utils'
import { isDateBetween, subDays, addDays } from 'lib/datetime/dateUtils'

const UpsellTileList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(12)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(272)}, 1fr));
  }

  &.lux-plus-sort-order {
    .insurance-upsell-tile {
      order: -1;
    }
  }
`

interface Props {
  order: App.Order;
  className?: string;
}

interface MappedProps {
  canViewLuxPlusBenefits: boolean;
  isEnabledLuxPlusDiscountedInsurance: boolean;
  upcomingOrders: Array<App.Order>;
}

function getExistingDatesSet(
  orders: Array<App.Order>,
  checkItem: (order: App.Order) => Array<{ date?: string | null } | undefined> | undefined,
): Set<string> {
  const datesSet = new Set<string>()

  orders.forEach(order => {
    const items = checkItem(order)
    items?.forEach(item => {
      if (item?.date) {
        datesSet.add(item.date)
      }
    })
  })
  return datesSet
}

function hasExistingItemInDateWindow(
  orders: Array<App.Order>,
  twoWeeksBeforeEarliestDate: Date,
  twoWeeksAfterLatestDate: Date,
  checkItem: (order: App.Order) => Array<{ date?: string | null } | undefined> | undefined,
): boolean {
  const datesSet = getExistingDatesSet(orders, checkItem)
  return Array.from(datesSet).some(dateStr => {
    const itemDate = new Date(dateStr)
    return isDateBetween(twoWeeksBeforeEarliestDate, twoWeeksAfterLatestDate, itemDate)
  })
}

function checkExistingDates(
  orders: Array<App.Order>,
  startDate: Date,
  endDate: Date,
  getItemDates: (order: App.Order) => Array<string | null | undefined>,
): boolean {
  const dates = orders.flatMap(getItemDates).filter(Boolean)
  if (dates.length === 0) return false

  return hasExistingItemInDateWindow(
    orders,
    startDate,
    endDate,
    order => getItemDates(order).map(date => ({ date })),
  )
}

function CompactBookingDetailsUpsellSection(props: Props & MappedProps) {
  const {
    order,
    className,
    canViewLuxPlusBenefits,
    isEnabledLuxPlusDiscountedInsurance,
    upcomingOrders,
  } = props

  const allUpsells = useAllUpsells(order)
  const upsellOrderDates = useMemo(() => getOrderDateRange(order), [order])
  const memoizedAllUpsells = useMemo(() => allUpsells, [allUpsells])

  const {
    tour: { tourUpsellEnabled, tourUpsell, handleTourUpsellDismiss },
    insurance: { insuranceUpsellEnabled, insuranceUpsell, handleInsuranceUpsellDismiss },
    flight: { flightUpsellEnabled, flightUpsell, handleFlightUpsellDismiss },
    airportTransfer: { airportTransferUpsellEnabled, airportTransferUpsell, handleAirportTransferDismiss },
    carHire: { carHireUpsellEnabled, carHireUpsell, handleCarHireUpsellDismiss },
    anyUpsellEnabled,
    anyUpsellLoading,
  } = memoizedAllUpsells

  const shouldPrioritiseInsurance = useMemo(
    () => canViewLuxPlusBenefits && isEnabledLuxPlusDiscountedInsurance,
    [canViewLuxPlusBenefits, isEnabledLuxPlusDiscountedInsurance],
  )

  const {
    hasExistingCarHire,
    hasExistingFlight,
    hasExistingTour,
    hasExistingTransfer,
    hasNoAvailableUpsells,
  } = useMemo(() => {
    const twoWeeksBeforeEarliestDate = subDays(new Date(upsellOrderDates.earliestDate ?? new Date()), 14)
    const twoWeeksAfterLatestDate = addDays(new Date(upsellOrderDates.latestDate ?? new Date()), 14)

    const hasExistingCarHire = checkExistingDates(
      upcomingOrders,
      twoWeeksBeforeEarliestDate,
      twoWeeksAfterLatestDate,
      order => order.carHireItems?.map(item => item.reservation?.pickUp?.date) ?? [],
    )

    const hasExistingFlight = checkExistingDates(
      upcomingOrders,
      twoWeeksBeforeEarliestDate,
      twoWeeksAfterLatestDate,
      order => order.flightItems?.map(item => item.departureDate) ?? [],
    )

    const hasExistingTour = checkExistingDates(
      upcomingOrders,
      twoWeeksBeforeEarliestDate,
      twoWeeksAfterLatestDate,
      order => order.tourItems?.map(item => item.tour?.startDate) ?? [],
    )

    const hasExistingTransfer = checkExistingDates(
      upcomingOrders,
      twoWeeksBeforeEarliestDate,
      twoWeeksAfterLatestDate,
      order => order.transferItems?.map(item => item.date) ?? [],
    )

    return {
      hasExistingCarHire,
      hasExistingFlight,
      hasExistingTour,
      hasExistingTransfer,
      hasNoAvailableUpsells: (
        (!tourUpsellEnabled || hasExistingTour) &&
        (!flightUpsellEnabled || hasExistingFlight) &&
        !insuranceUpsellEnabled &&
        (!airportTransferUpsellEnabled || hasExistingTransfer) &&
        (!carHireUpsellEnabled || hasExistingCarHire)
      ),
    }
  }, [
    upcomingOrders,
    upsellOrderDates,
    tourUpsellEnabled,
    flightUpsellEnabled,
    insuranceUpsellEnabled,
    airportTransferUpsellEnabled,
    carHireUpsellEnabled,
  ])

  if (anyUpsellLoading || !anyUpsellEnabled) return null

  return <Group className={className} gap={16} direction="vertical">
    {!hasNoAvailableUpsells && (
      <Group direction="horizontal" gap={8}>
        <HeadingTextBlock variant="heading5" endIcon={
          <Tooltip
            description="Prices shown are estimates, with the final amount displayed at the next step. Free cancellation policy may change based on your selected dates."
          >
            <LineInfoCircleIcon />
          </Tooltip>
        }
        >
          What's next to plan?
        </HeadingTextBlock>
      </Group>
    )}
    <UpsellTileList className={cn({ 'lux-plus-sort-order': shouldPrioritiseInsurance })}>
      {tourUpsellEnabled && !hasExistingTour && <TourOptionUpsellTile tourUpsellData={tourUpsell} variant="compact" onDismiss={handleTourUpsellDismiss}/>}
      {flightUpsellEnabled && !hasExistingFlight && <FlightUpsellTile flightUpsellData={flightUpsell} variant="compact" onDismiss={handleFlightUpsellDismiss} />}
      {insuranceUpsellEnabled && <InsuranceUpsellTile insuranceUpsellData={insuranceUpsell} variant="compact" onDismiss={handleInsuranceUpsellDismiss}/>}
      {airportTransferUpsellEnabled && !hasExistingTransfer && <AirportTransferUpsellTile transferUpsellData={airportTransferUpsell} variant="compact" onDismiss={handleAirportTransferDismiss}/>}
      {carHireUpsellEnabled && !hasExistingCarHire && <CarHireUpsellTile carHireUpsellData={carHireUpsell} variant="compact" onDismiss={handleCarHireUpsellDismiss}/>}
    </UpsellTileList>
  </Group>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  isEnabledLuxPlusDiscountedInsurance: isEnabledLuxPlusDiscountedInsurance(state),
  upcomingOrders: getUpcomingOrders(state),
})

export default connect(mapStateToProps)(CompactBookingDetailsUpsellSection)
