import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React, { ComponentProps, forwardRef, MouseEventHandler, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import FormatCurrency from './FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'
import Clickable from './Clickable'
import LoadingIndicator from './Loading/LoadingIndicator'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowLuxPlusPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPrice'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import IconButton from 'components/Luxkit/Button/IconButton'

const Sheet = styled(Group)`
  padding: ${rem(20)};
  background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
`

const Loader = styled(LoadingIndicator)`
  padding: 0;
`

interface Props {
  onClick?: () => void
  title: string;
  subTitle?: string;
  IconComponent: React.ComponentType<SvgIconProps>;
  description: string;
  price?: number;
  priceUnit?: string;
  isPriceLoading?: boolean
  to?: string;
  currencyFormat?: ComponentProps<typeof FormatCurrency>['format'];
  luxPlusPrice?: number;
  className?: string;
  onDismissClick?: () => void;
}

const OrderUpsellTile = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    title,
    subTitle,
    IconComponent,
    description,
    to,
    onClick,
    price,
    priceUnit,
    currencyFormat = 'roundedDollar',
    isPriceLoading,
    luxPlusPrice,
    className,
    onDismissClick,
  } = props
  const showLuxPlusPricing = !!luxPlusPrice && priceUnit && !isPriceLoading
  const showPricing = !!price && priceUnit && !showLuxPlusPricing && !isPriceLoading

  const saveAmount = useMemo((): number => {
    if (!price || !luxPlusPrice) return 0
    return (price - luxPlusPrice)
  }, [price, luxPlusPrice])

  const handleDismissClick = useCallback<MouseEventHandler<HTMLButtonElement>>(event => {
    event.preventDefault()
    onDismissClick?.()
  }, [onDismissClick])

  return <Clickable ref={ref} to={to} preventSwipeClick onClick={onClick} className={className}>
    <Sheet direction="vertical" gap={20} verticalAlign="space-between" fullHeight fullWidth>
      <Group direction="vertical" gap={8}>
        <Group direction="horizontal" gap={8} horizontalAlign="space-between">
          <BodyTextBlock
            startIcon={<IconComponent colour="highlight-tertiary" size="M" />}
            variant="large"
            weight="bold"
          >
            {title}
          </BodyTextBlock>
          <IconButton kind="tertiary" onClick={handleDismissClick} size="small">
            <LineTimesIcon />
          </IconButton>
        </Group>
        {subTitle && <Caption variant="large" weight="bold">{subTitle}</Caption>}
        <BodyText variant="medium">
          {description}
        </BodyText>
      </Group>
      <VerticalSpacer gap={4}>
        {showLuxPlusPricing && !!saveAmount && <LuxPlusLabel saveAmount={saveAmount} type="insurance" />}
        <Group
          direction="horizontal"
          horizontalAlign={showPricing || showLuxPlusPricing || isPriceLoading ? 'space-between' : 'end'}
          verticalAlign={showLuxPlusPricing ? 'end' : 'center'}
        >
          {isPriceLoading && <Loader inline ballSize={8} />}
          {showPricing &&
            <div>
              <BodyText variant="medium">
                From
              </BodyText>
              <BodyText variant="medium">
                <b><FormatCurrency value={price} format={currencyFormat} /></b> /{priceUnit}
              </BodyText>
            </div>
          }
          {showLuxPlusPricing &&
            <div>
              <BodyText variant="medium">
                From
              </BodyText>
              <div>
                <PriceRowLuxPlusPrice
                  memberPrice={luxPlusPrice}
                  size="M"
                  saleUnit={priceUnit}
                  priceFormat="dynamicDollar"
                />
                <div>
                  <PriceRowSale value={price ?? 0} priceFormat="dynamicDollar" />
                  <Caption
                    as="s"
                    variant="large"
                    colour="neutral-two"
                  >
                    {' '}/{priceUnit}
                  </Caption>
                </div>
              </div>
            </div>
          }
          {(to || onClick) && <TextButton kind="secondary" nonInteractable>
            View options
          </TextButton>}
        </Group>
      </VerticalSpacer>
    </Sheet>
  </Clickable>
})

export default OrderUpsellTile
