import BodyText from 'components/Luxkit/Typography/BodyText'
import React, { ComponentProps, forwardRef, MouseEventHandler, useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import FormatCurrency from './FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'
import Clickable from './Clickable'
import LoadingIndicator from './Loading/LoadingIndicator'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import PriceRowLuxPlusPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPrice'

const StyledIconButton = styled(IconButton)`
    transform: translateY(-${rem(4)}) translateX(${rem(4)});
`

const ContentGroup = styled(Group)`
  flex-grow: 1;
`

const Sheet = styled(Group)`
  padding: ${rem(20)};
  background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
`

const Loader = styled(LoadingIndicator)`
  padding: 0;
`

interface Props {
  onClick?: () => void
  title: string;
  IconComponent: React.ComponentType<SvgIconProps>;
  price?: number;
  priceUnit?: string;
  isPriceLoading?: boolean
  to?: string;
  currencyFormat?: ComponentProps<typeof FormatCurrency>['format'];
  luxPlusPrice?: number;
  className?: string;
  tileType: 'car_hire' | 'flight' | 'transfer' | 'insurance' | 'tour';
  onDismissClick?: () => void;
}

const CompactOrderUpsellTile = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    title,
    IconComponent,
    to,
    onClick,
    price,
    priceUnit,
    currencyFormat = 'roundedDollar',
    isPriceLoading,
    luxPlusPrice,
    className,
    tileType,
    onDismissClick,
  } = props

  const showLuxPlusPricing = !!luxPlusPrice && priceUnit && !isPriceLoading
  const showPricing = !!price && priceUnit && !showLuxPlusPricing && !isPriceLoading
  const isCarHireTile = tileType === 'car_hire'
  const isFlightTile = tileType === 'flight'

  const handleDismissClick = useCallback<MouseEventHandler<HTMLButtonElement>>(event => {
    event.preventDefault()
    onDismissClick?.()
  }, [onDismissClick])

  return <Clickable ref={ref} to={to} preventSwipeClick onClick={onClick} className={className}>
    <Sheet direction="vertical" tabletDirection="horizontal-reverse" gap={4} fullHeight fullWidth>
      <Group direction="horizontal" horizontalAlign="space-between" tabletDirection="vertical-reverse" tabletHorizontalAlign="end" tabletVerticalAlign="space-between" gap={8}>
        <IconComponent colour="highlight-tertiary" size="L" />
        <StyledIconButton kind="tertiary" onClick={handleDismissClick} size="small">
          <LineTimesIcon />
        </StyledIconButton>
      </Group>
      <ContentGroup direction="vertical" verticalAlign="space-between" gap={32}>
        <Heading variant="heading6">
          {title}
        </Heading>
        <div>
          {isPriceLoading && <Loader inline ballSize={8} />}
          {isCarHireTile && !showPricing && !showLuxPlusPricing && <Group direction="vertical">
            <BodyTextBlock variant="small" startIcon={<LineCheckIcon colour="highlight-tertiary" size="S" />}>
              Free cancellation available
            </BodyTextBlock>
            <BodyTextBlock variant="small" startIcon={<LineCheckIcon colour="highlight-tertiary" size="S" />}>
              24/7 support
            </BodyTextBlock>
          </Group>
          }
          {isFlightTile && !showPricing && !showLuxPlusPricing && <Group direction="vertical">
            <BodyTextBlock variant="small" startIcon={<LineCheckIcon colour="highlight-tertiary" size="S" />}>
              Great fares from major airlines
            </BodyTextBlock>
            <BodyTextBlock variant="small" startIcon={<LineCheckIcon colour="highlight-tertiary" size="S" />}>
              24/7 support
            </BodyTextBlock>
          </Group>
          }
          {showPricing &&
            <div>
              <BodyText variant="medium">
                From
              </BodyText>
              <BodyText variant="medium">
                <b><FormatCurrency value={price} format={currencyFormat} /></b> /{priceUnit}
              </BodyText>
            </div>
          }
          {showLuxPlusPricing &&
            <div>
              <BodyText variant="medium">
                From
              </BodyText>
              <div>
                <PriceRowLuxPlusPrice
                  memberPrice={luxPlusPrice}
                  size="M"
                  saleUnit={priceUnit}
                  priceFormat="dynamicDollar"
                />
                <div>
                  <PriceRowSale value={price ?? 0} priceFormat="dynamicDollar" />
                  <Caption
                    as="s"
                    variant="large"
                    colour="neutral-two"
                  >
                    {' '}/{priceUnit}
                  </Caption>
                </div>
              </div>
            </div>
          }
        </div>
      </ContentGroup>
    </Sheet>
  </Clickable>
})

export default CompactOrderUpsellTile
