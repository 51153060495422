import { isItemBedbankUpcoming, isItemUpcoming } from './isUpcoming'

// since bedbank items are separate from order.items we don't need to check for them here
const SUPPORTED_ORDER_ITEM_OFFER_TYPES: Array<App.OfferType> = [
  'hotel',
  'bundle_and_save',
  'last_minute_hotel',
  'rental',
  'tactical_ao_hotel',
]

export function upsellEnabledForOrder(order: App.Order): boolean {
  if (order.locked) return false

  const upcomingAndSupportedItems:Array<App.OrderItem | App.OrderBedbankItem> = order.items.filter(item => {
    const supported = SUPPORTED_ORDER_ITEM_OFFER_TYPES.includes(item.offer.type)
    const upcomingAndSupported = isItemUpcoming(item) && supported
    if (item.offer.type === 'hotel') {
      // BNBLs are not supported for upsell so check for reservation (i.e. that they have dates) on flash
      return upcomingAndSupported && item.reservation
    }
    return upcomingAndSupported
  })

  if (upcomingAndSupportedItems.length > 0) return true

  return order.bedbankItems.some(item => isItemBedbankUpcoming(item))
}

export function getUpsellDismissalStorageKey(upsell: string, orderId: string) {
  return `${upsell}-${orderId}-upsell-dismissed`
}
