import { initialiseCheckout } from 'actions/CheckoutActions'
import { CheckoutPageId } from 'checkout/constants/pages'
import usePurchasableTourOptionalExperience from 'checkout/hooks/usePurchasableTourOptionalExperience'
import { navigateToTourCheckoutStep } from 'components/App/Header/HeaderCheckout/StepsHeaderCheckout/steps/tours'
import useOffer from 'hooks/Offers/useOffer'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getUpsellDismissalStorageKey } from 'lib/order/upsellUtils'
import { findCheapestTourExperience } from 'lib/tours/tourUtils'
import { useCallback, useMemo, useState } from 'react'
import {
  get as getLocalStorage,
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import useTourV2Snapshot from 'hooks/TourV2/useTourV2Snapshot'

function useTourUpsell(
  order: App.Order,
): {
  tourUpsellEnabled: true
  tourUpsell: App.TourUpsell
  handleTourUpsellDismiss: () => void
  tourDataLoading: boolean
} | {
  tourUpsellEnabled: false
  tourUpsell: undefined
  handleTourUpsellDismiss: undefined
  tourDataLoading: boolean
} {
  const dispatch = useAppDispatch()
  const [dismissed, setDismissed] = useState<boolean>(!!getLocalStorage(getUpsellDismissalStorageKey('tour', order.id)))

  const handleTourUpsellDismiss = useCallback(() => {
    setLocalStorage(getUpsellDismissalStorageKey('tour', order.id), true)
    setDismissed(true)
  }, [order.id])

  const [tourOffer, fetching] = useOffer<App.Tours.TourV2Offer>(order.tourItems[0]?.tourId)
  const item = order.tourItems[0]
  const [snapshot, snapshotLoading] = useTourV2Snapshot(item?.reservationId)

  const onClick = useCallback(() => {
    dispatch(initialiseCheckout([], {
      regionCode: order.regionCode,
      currencyCode: order.currencyCode,
      mode: 'tour-optional-experiences',
      order,
    }))
    dispatch(navigateToTourCheckoutStep(CheckoutPageId.TourV2OptionalExperience))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  const cheapestTourExperience = useMemo<App.Tours.TourV2OfferPurchasableOption | undefined>(() => {
    if (tourOffer?.purchasableExperienceOptions) {
      return findCheapestTourExperience(Object.values(tourOffer?.purchasableExperienceOptions))
    }
  }, [tourOffer])

  const hasOptionalExperiencesStillPurchasable = usePurchasableTourOptionalExperience(item, tourOffer, snapshot)

  const enabled = (
    dismissed &&
    !!order.tourItems.length &&
    hasOptionalExperiencesStillPurchasable
  )

  const tourUpsell = useMemo<App.TourUpsell>(() => {
    return {
      onClick,
      price: cheapestTourExperience?.price,
    }
  }, [cheapestTourExperience?.price, onClick])

  const tourDataLoading = fetching || snapshotLoading

  if (enabled) {
    return {
      tourUpsellEnabled: true,
      tourUpsell,
      handleTourUpsellDismiss,
      tourDataLoading,
    }
  } else {
    return {
      tourUpsellEnabled: false,
      tourUpsell: undefined,
      handleTourUpsellDismiss: undefined,
      tourDataLoading,
    }
  }
}

export default useTourUpsell
